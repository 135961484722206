// :root CSS dark variables


[data-layout-mode="dark"]{

  // Color system - Dark Mode only
  $gray-100: #212529;
  $gray-200: #2a2a42;
  $gray-300: #2d2d49;
  $gray-400: #9295a4;
  $gray-500: #858592;
  $gray-600: #c3cbe4;
  $gray-700: #d8d8df;
  $gray-800: #eff2f7;
  $gray-900: #f8f9fa;

  $grays: (
      "100": $gray-100,
      "200": $gray-200,
      "300": $gray-300,
      "400": $gray-400,
      "500": $gray-500,
      "600": $gray-600,
      "700": $gray-700,
      "800": $gray-800,
      "900": $gray-900
  );

  // Prefix for :root CSS variables
  @each $color, $value in $grays {
      --#{$variable-prefix}gray-#{$color}: #{$value};
  }

  // Vertical Sidebar - Default Light

  --#{$variable-prefix}sidebar-bg: #ffffff;
  --#{$variable-prefix}sidebar-border-color: #e9ebef;
  --#{$variable-prefix}sidebar-menu-item-color: #7b8190;
  --#{$variable-prefix}sidebar-menu-sub-item-color: #7b8190;
  --#{$variable-prefix}sidebar-menu-item-icon-color: #7b8190;
  --#{$variable-prefix}sidebar-menu-item-hover-color: #383c40;
  --#{$variable-prefix}sidebar-menu-item-active-color: #776acf;
  --#{$variable-prefix}sidebar-menu-sub-item-active-color: #383c40;
  --#{$variable-prefix}sidebar-menu-item-active-bg-color: #eeedf9;


  // Vertical Sidebar - Dark
  --#{$variable-prefix}sidebar-dark-bg: #24243c;
  --#{$variable-prefix}sidebar-dark-menu-item-color: #9292a9;
  --#{$variable-prefix}sidebar-dark-menu-sub-item-color: #9292a9;
  --#{$variable-prefix}sidebar-dark-menu-item-icon-color: #9292a9;
  --#{$variable-prefix}sidebar-dark-menu-item-hover-color: #d8d8df;
  --#{$variable-prefix}sidebar-dark-menu-item-active-color: #d8d8df;
  --#{$variable-prefix}sidebar-dark-menu-item-active-bg-color: #2c2c45;

  // Topbar - Deafult Light
  --#{$variable-prefix}header-bg: #ffffff;
  --#{$variable-prefix}header-item-color: #555b6d;

  // Topbar - Dark
  --#{$variable-prefix}header-dark-bg: radial-gradient(at 50% -20%,#24243c,#222236) fixed;
  --#{$variable-prefix}header-dark-item-color: #e9ecef;

  // Topbar Search
  --#{$variable-prefix}topbar-search-bg: #f8f7fc;

  // Footer
  --#{$variable-prefix}footer-bg: #24243c;
  --#{$variable-prefix}footer-color: #d8d8df;

  // Horizontal nav
  --#{$variable-prefix}menu-item-color: #9292a3;
  --#{$variable-prefix}menu-item-active-color: #776acf;
  --#{$variable-prefix}topnav-dark-bg: #03314D;
  --#{$variable-prefix}menu-dark-item-color: rgba(255,255,255, 0.5);
  --#{$variable-prefix}menu-item-active-border-color: #776acf;
  --#{$variable-prefix}menu-dark-item-hover-color: #ffffff;
  --#{$variable-prefix}menu-dark-item-active-color: #fff;

  // Brand 
  --#{$variable-prefix}navbar-brand-box-bg: #ffffff;

  // apex charts
  --#{$variable-prefix}apex-grid-color: #32394e;

  // body
  --#{$variable-prefix}body-bg: #1b1c31;
  --#{$variable-prefix}body-color: #{$gray-400};
  --#{$variable-prefix}body-color-rgb: #{to-rgb($body-color)};

    // Display
  --#{$variable-prefix}display-none: block;
  --#{$variable-prefix}display-block: none;

  // card-title-desc
  --#{$variable-prefix}card-title-desc: #{$gray-400};

  // Horizontal nav
  --#{$variable-prefix}topnav-bg: #{lighten($gray-200, 2%)};
  --#{$variable-prefix}topnav-item-color: #{$gray-400};
  --#{$variable-prefix}topnav-item-color-active: #{$white};

  --#{$variable-prefix}light: #{$gray-300};
  --#{$variable-prefix}light-rgb: #{to-rgb($gray-300)};
  --#{$variable-prefix}dark: #{$gray-800};
  --#{$variable-prefix}dark-rgb: #{to-rgb($gray-800)};
  --#{$variable-prefix}text-muted: #{$gray-500};

  // link
  --#{$variable-prefix}link-color: #{$gray-700};
  --#{$variable-prefix}link-hover-color: #{$gray-700};

  // Border variable
  --#{$variable-prefix}border-color:  #{$gray-300};

  // dropdown
  --#{$variable-prefix}dropdown-bg: #{$gray-200};
  --#{$variable-prefix}dropdown-link-color: #{$gray-500};
  --#{$variable-prefix}dropdown-link-hover-color: #{shade-color($gray-900, 5%)};
  --#{$variable-prefix}dropdown-link-hover-bg: #{$gray-300};
  --#{$variable-prefix}dropdown-border-width: 1px;

  // card
  --#{$variable-prefix}card-bg: #24243c;
  --#{$variable-prefix}card-cap-bg: #24243c;
  --#{$variable-prefix}card-border-color: #2b2b46;
  --#{$variable-prefix}card-logo-dark: none;
  --#{$variable-prefix}card-logo-light: block;

  // modal
  --#{$variable-prefix}modal-bg: #{$gray-200};
  --#{$variable-prefix}modal-content-bg: #{$gray-200};
  --#{$variable-prefix}modal-content-border-color: #{$gray-300};

  // nav tabs
  --#{$variable-prefix}nav-tabs-link-active-color: #{$gray-900};
  --#{$variable-prefix}nav-tabs-link-active-bg: #{$gray-300};

    // accordion
  --#{$variable-prefix}accordion-button-active-color: #{$white};
  --#{$variable-prefix}accordion-bg: #{transparent};
  --#{$variable-prefix}accordion-button-bg: #{$gray-300};

  // table
  --#{$variable-prefix}table-color: #{$gray-400};

  // Boxed layout 
  --#{$variable-prefix}boxed-body-bg:       #14142b;

  // progress
  --#{$variable-prefix}progress-bg: #{$gray-300};

  // toast
  --#{$variable-prefix}toast-background-color: #{rgba($gray-300, .85)};
  --#{$variable-prefix}toast-border-color: #{rgba($white, .1)};
  --#{$variable-prefix}toast-header-border-color: #{rgba($white, .05)};

  //list
  --#{$variable-prefix}list-group-hover-bg: #{lighten($gray-200, 2.5%)};

  // popover
  --#{$variable-prefix}popover-bg: #{lighten($gray-200, 1%)};

  // pagination
  --#{$variable-prefix}pagination-hover-bg: #{lighten($gray-200, 4%)};

  //form
  --#{$variable-prefix}input-bg: #{lighten($gray-200, 2%)};
  --#{$variable-prefix}input-group-addon-border-color: #{$gray-300};
  --#{$variable-prefix}input-border: #363654;
  --#{$variable-prefix}input-border-color: #{$gray-300};
  --#{$variable-prefix}input-focus-border: #{lighten($gray-300, 4%)};
  --#{$variable-prefix}input-disabled-bg: #{$gray-200};

  // table
  --#{$variable-prefix}table-head-bg: #{$gray-300};

  // input-group-addon
  --#{$variable-prefix}input-placeholder-color: #{$gray-400};
  --#{$variable-prefix}input-group-addon-bg: #363652;

  //check
  --#{$variable-prefix}input-check-border:  #{lighten($gray-300, 4%)};

}