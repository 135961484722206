// 
// Chat.scss
//

.chat-leftsidebar{
  @media (min-width: 1200px) {
    min-width: 380px;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    min-width: 290px;
  }
}

.chat-noti-dropdown{
  &.active{
    &:before{
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $danger;
      border-radius: 50%;
      right: 0;
    }
  }

  .btn{
    padding: 6px;
    box-shadow: none;
    font-size: 16px;
    color: var(--#{$variable-prefix}gray-600);
  }
}

.chat-user-status{
  position: relative;
  .status{
    width: 12px;
    height: 12px;
    background-color: $success;
    border-radius: 50%;
    border: 2px solid $white;
    position: absolute;
    left: 60px;
    right: 0;
    margin: 0 auto;
    bottom: 5px;
  }
}

.chat-search-box{
  .form-control{
    border: 0;
  }
}

.chat-message-list{
  height: calc(100vh - 280px);
  @media (min-width: 992px){
    height: calc(100vh - 335px);
  }
}

.chat-rightsidebar{
  @media (min-width: 992px){
    height: calc(100vh - 690px);
  }
}

.chat-list{
  margin: 0;
  li{

    &.active{
        a{
          background-color: rgba($primary, 0.075);
          border-color: transparent;
        }
    }
    
    a{
      position: relative;
      display: block;
      color: $text-muted;
      transition: all 0.4s;
      padding: 12px;
      border: 1px solid $border-color;
      border-radius: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      margin: 12px 0px;

      &:hover{
        background-color: rgba($primary, 0.075);
        border-color: transparent;
      }
    }


    .user-img{
      position: relative;

      .user-status{
        width: 10px;
        height: 10px;
        background-color: $gray-500;
        border-radius: 50%;
        border: 2px solid $card-bg;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      &.online{
        .user-status{
          background-color: $success;
        }
      }

      &.away{
        .user-status{
          background-color: $warning;
        }
      }
    }

    &.unread{
      a{
        font-weight: $font-weight-semibold;
        color: $dark;
      }
    }

    .unread-message {
      position: absolute;
      display: inline-block;
      right: 16px;
      top: 33px;
    }
  }
}


.group-list{
  li{
    a{
      border: 0;
    }
  }
}


.user-chat-nav{
  .dropdown{
    .nav-btn{
      height: 36px;
      width: 36px;
      line-height: 36px;
      box-shadow: none;
      padding: 0;
      font-size: 16px;
      border-radius: 50%;
      color: var(--#{$variable-prefix}gray-600);
    }
  }
}
.contact-list{
  font-size: 12px;
  text-transform: uppercase;
  color: $text-muted;
  font-weight: $font-weight-semibold;
}

.chat-conversation{
  height: calc(100vh - 300px);
  @media (min-width: 992px){
    height: calc(100vh - 335px);
  }
  li{
   clear: both;
  }


  .chat-day-title{
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    border: none;

    .title{
      display: inline-block;
      background-color: var(--#{$variable-prefix}light);
      position: relative;
      z-index: 1;
      padding: 4px 16px;
      border-radius: 30px;
    }

    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 16px;
    }
    .badge{
      font-size: 12px;
    }
  }

  .chat-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }


 .conversation-list{
   margin-bottom: 45px;
   position: relative;
   @media (min-width: 992px) {
    max-width: 85%;
   }

   .ctext-wrap {
     display: flex;
     margin-bottom: 20px;
   }

   .ctext-wrap-content {
     padding: 14px 20px;
     background-color: rgba($primary,0.1);
     border-radius: 0px 8px 8px 8px;
     color: $text-muted;
     position: relative;
     margin-left: 16px;

     &:before {
         content: "";
         position: absolute;
         border: 5px solid transparent;
         border-right-color: rgba($primary,0.1);
         border-top-color: rgba($primary,0.1);
         left: -10px;
         top: 0;
     }
   }

 .conversation-name {
   margin-bottom: 4px;
   display: flex;
   font-size: 13px;
    .time{
    font-size: 12px;
    position: absolute;
    left: -50px;
    top: 54px;
    bottom: 0;
    color: $text-muted;
    font-weight: 400;
    -webkit-transition: all .4s;
    transition: all .4s;
    }

    .user-name{
      color: $dark;
    }
 }


 .dropdown {
     .dropdown-toggle {
         font-size: 18px;
         padding: 4px;
         color: $gray-600;

         @media (max-width: 575.98px) {
             display: none;
         }
     }

 }

 .chat-time {
     font-size: 12px;
     margin-top: 4px;
     text-align: right;
     color: rgba($white, 0.5);
 }


 .message-img {
   position: relative;
   display: flex;
   align-items: flex-start;
   flex-wrap: wrap;
   gap: 10px;

     .message-img-list {
         position: relative;
     }

     img {
         max-width: 80px;
     }
   }
 }

 .right {

   .conversation-list {
       float: right;
       text-align: right;

       .ctext-wrap {
           .ctext-wrap-content {
               order: 2;
               background-color: $primary;
               text-align: right;
               border-radius: 8px 0px 8px 8px;
               margin-left: 0;
               margin-right: 6px;
               color: $white;

               &:before {
                   border: 5px solid transparent;
                   border-top-color: $primary;
                   border-left-color: $primary;
                   left: auto;
                   right: -10px;
               }
           }

           .conversation-name {
             justify-content: flex-end;
            .time{
              order: 1;
              margin-left: 0;
              margin-right: 8px;
              position: absolute;
              right: -50px;
              left: auto;
            }

            .user-name{
                order: 2;
              }
           }
       }

       .dropdown {
           order: 1;
       }
       .chat-avatar{
         order: 3;
       }
     }
 }
}

.chat-input-section{
 border-top: 1px solid $border-color;
}

.chat-input{
 background-color: var(--#{$variable-prefix}light) !important;
 border-color:  var(--#{$variable-prefix}light) !important;
}


.chat-send{
 @media (max-width: 575.98px) {
   min-width: auto;
 }
}
